import { ORDEREDLAYERS } from "../libraries/Constants2";
/* eslint-disable no-unused-vars */
import { TEMP_STATE } from "../tempState";

const coordinates = {
    CCR_Suction_MT: {
        startX: 2.8539,
        startY: 53.5559,
        endX: 27.4129,
        endY: 53.5559,
        materialDetails: [
            {
                "label": "Line Material",
                "name": "lineMaterial",
                "type": "select",
                "options": [
                    {
                        "label": "K65 inch",
                        "value": "k65_inch"
                    },
                    {
                        "label": "Copper mm",
                        "value": "copper_mm"
                    }
                ],
                "error": "",
                "defaultValue": "k65_inch",
                "value": "copper_mm",
                "selectedUnit": ""
            },
            {
                "label": "Maximum Pressure Drop",
                "name": "maxPressureDropAllowedInKelvin",
                "type": "input",
                "inputType": "number",
                "units": [
                    "k",
                    "m/s"
                ],
                "error": "",
                "defaultValue": "",
                "value": 1.5,
                "selectedUnit": "k"
            },
            {
                "label": "Maximum Velocity",
                "name": "maxSpeedAllowedInMetersPerSecond",
                "type": "input",
                "inputType": "number",
                "units": [
                    "m/s",
                    "k"
                ],
                "error": "",
                "defaultValue": "",
                "value": 20.0,
                "selectedUnit": "m/s"
            }
        ]
    },
    CCR_Liquid_LT_MT: {
        startX: 2.8539,
        startY: 53.7559,
        endX: 27.2129,
        endY: 53.7559,
        materialDetails: [
            {
                "label": "Line Material",
                "name": "lineMaterial",
                "type": "select",
                "options": [
                    {
                        "label": "K65 inch",
                        "value": "k65_inch"
                    },
                    {
                        "label": "Copper mm",
                        "value": "copper_mm"
                    }
                ],
                "error": "",
                "defaultValue": "k65_inch",
                "value": "copper_mm",
                "selectedUnit": ""
            },
            {
                "label": "Maximum Pressure Drop",
                "name": "maxPressureDropAllowedInKelvin",
                "type": "input",
                "inputType": "number",
                "units": [
                    "k",
                    "m/s"
                ],
                "error": "",
                "defaultValue": "",
                "value": 1,
                "selectedUnit": "k"
            },
            {
                "label": "Maximum Velocity",
                "name": "maxSpeedAllowedInMetersPerSecond",
                "type": "input",
                "inputType": "number",
                "units": [
                    "m/s",
                    "k"
                ],
                "error": "",
                "defaultValue": "",
                "value": 1.5,
                "selectedUnit": "m/s"
            },
            {
                "label": "Maximum Velocity before consumer",
                "name": "maxAllowedSpeedBeforeConsumerInMetersPerSecond",
                "type": "input",
                "inputType": "number",
                "units": [
                    "m/s",
                    "k"
                ],
                "error": "",
                "defaultValue": "",
                "value": 20.0,
                "selectedUnit": "m/s"
            }
        ]
    },
    CCR_Condensate_Line: {
        startX: '',
        startY: '',
        endX: '',
        endY: ''
    },
    CCR_Discharge_Line: {
        startX: '',
        startY: '',
        endX: '',
        endY: ''
    },
    // Last one Pipe blue
    CCR_Suction_LT: {
        startX: 26.2459,
        startY: 66.8584,
        endX: 26.2459,
        endY: 67.4664,
        materialDetails: [
            {
                "label": "Line Material",
                "name": "lineMaterial",
                "type": "select",
                "options": [
                    {
                        "label": "K65 inch",
                        "value": "k65_inch"
                    },
                    {
                        "label": "Copper mm",
                        "value": "copper_mm"
                    }
                ],
                "error": "",
                "defaultValue": "k65_inch",
                "value": "copper_mm",
                "selectedUnit": ""
            },
            {
                "label": "Maximum Pressure Drop",
                "name": "maxPressureDropAllowedInKelvin",
                "type": "input",
                "inputType": "number",
                "units": [
                    "k",
                    "m/s"
                ],
                "error": "",
                "defaultValue": "",
                "value": 1,
                "selectedUnit": "k"
            },
            {
                "label": "Maximum Velocity",
                "name": "maxSpeedAllowedInMetersPerSecond",
                "type": "input",
                "inputType": "number",
                "units": [
                    "m/s",
                    "k"
                ],
                "error": "",
                "defaultValue": "",
                "value": 1.5,
                "selectedUnit": "m/s"
            },
            {
                "label": "Maximum Velocity before consumer",
                "name": "maxAllowedSpeedBeforeConsumerInMetersPerSecond",
                "type": "input",
                "inputType": "number",
                "units": [
                    "m/s",
                    "k"
                ],
                "error": "",
                "defaultValue": "",
                "value": 20.0,
                "selectedUnit": "m/s"
            }
        ]
    },
    CCR_Hot_gas: {
        startX: '',
        startY: '',
        endX: '',
        endY: ''
    }
}

// const initialState = TEMP_STATE;

const initialState = []

const generateProjectTemplate = ( projectId ) => {
    return {
        projectId,
        ...ORDEREDLAYERS.reduce((acc, key) => {
            acc[key.name] = {
                // startX: coordinates[key.name].startX,
                // startY: coordinates[key.name].startY,
                // endX: coordinates[key.name].endX,
                // endY: coordinates[key.name].endY,
                startX: null,
                startY: null,
                endX: null,
                endY: null,
                data: [],
                // materialDetails: coordinates[key.name].materialDetails
                materialDetails: []
            }
            return acc
        }, {}),
        cabinets: [],
        airCooler: [],
        gasCooler: [],
        groups: [],
        tags: [],
        uploadedData: [],
        loadedLayers: [],
        pipingCalculation: {
            pipingsResult: null,
            isLoading: false
        },
        pointingLines: [],
        existingPipeLayers: [],
        risers: []
    }
}

// const initialState = {
//     ...ORDEREDLAYERS.reduce((acc, key) => {
//         acc[key.name] = {
//             startX: coordinates[key.name].startX,
//             startY: coordinates[key.name].startY,
//             endX: coordinates[key.name].endX,
//             endY: coordinates[key.name].endY,
//             // startX: null,
//             // startY: null,
//             // endX: null,
//             // endY: null,
//             data: [],
//             // materialDetails: coordinates[key.name].materialDetails
//             materialDetails: []
//         }
//         return acc
//     }, {}),
//     cabinets: [],
//     airCooler: [],
//     gasCooler: [],
//     groups: [],
//     tags: [],
//     uploadedData: [],
//     loadedLayers: [],
//     pipingCalculation: {
//         pipingsResult: null,
//         isLoading: false
//     },
//     pointingLines: [],
//     existingPipeLayers: [],
//     risers: []
// };

//Design 2
// const initialState2 = {
//     Carrier_Aspiratie_MT: {
//         startX: 179.5067,
//         startY: 6737.3756,
//         endX: 179.5067,
//         endY: 6757.2185,
//         data: []
//     },
//     Carrier_Lichid_MT_LT: {
//         startX: 179.6910,
//         startY: 6737.2185,
//         endX: 179.6910,
//         endY: 6757.2185,
//         data: []
//     },
//     Carrier_Aspiratie_LT: {
//         startX: 180.7081,
//         startY: 6757.8404,
//         endX: 185.9079,
//         endY: 6757.8404,
//         data: [],
//     },
//     Carrier_CCR_DISCHARGE_LINE: {
//         startX: 180.7081,
//         startY: 6757.6408,
//         endX: 185.7081,
//         endY: 6757.6408,
//         data: [],
//     },
//     cabinets: [],
//     airCooler: [],
//     gasCooler: [],
//     groups: [],
//     tags: [],
//     racks: [],
//     uploadedData: [],
//     loadedLayers: [],
//     pipingsResult: null,
//     pointingLines: []
// };

const guiReducer = (state = initialState, action) => {
    const layer = action.layer;
    switch (action.type) {
        case 'ADD_PROJECT':
            const stateData = state.find( project => {
                return project.projectId === action.payload 
            });
            if ( stateData ) {
                return state
            }
            return [ ...state, generateProjectTemplate( action.payload ) ];
        case 'ALL_LAYERS_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, uploadedData: action.payload} : project
            });
        case 'PROCESS_PIPES_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, [layer]: { ...project[layer], data: action.payload.mtPipes }} : project
            });
        case 'PROCESS_CABINETS_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, cabinets: action.payload} : project
            });
        case 'PROCESS_GROUPS_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, groups: action.payload} : project
            });
        case 'PROCESS_TAGS_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, tags: action.payload} : project
            });
        case 'PROCESS_AIRCOOLER_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, airCooler: action.payload} : project
            });
        case 'PROCESS_POINTING_LINES_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, pointingLines: action.payload} : project
            });
        case 'SAVE_LOADED_LAYERS':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, loadedLayers: action.payload} : project
            })
        case 'PROCESS_RACKS_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, racks: action.payload} : project
            });
        case 'SAVE_START_AND_END_COORDINATES':
            return state.map( project => {
                const updatedState = { ...project };
                Object.keys(action.payload).forEach((key) => {
                    if (updatedState[key]) {
                        updatedState[key] = {
                            ...updatedState[key],
                            ...action.payload[key]
                        }
                    } 
                    // else {
                    //     updatedState[key] = action.payload[key];
                    // }
                });
                return project.projectId === action.projectId ? updatedState : project
            });
        case 'PIPING_CALCULATION_RESULT':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, pipingCalculation: {
                    pipingsResult: action.payload.response,
                    isLoading: action.payload.isLoading
                }} : project
            });
            // return {
            //     ...state,
            //     ...state.pipingCalculation,
            //     pipingCalculation: {
            //         pipingsResult: action.payload.response,
            //         isLoading: action.payload.isLoading
            //     }
            // }
        case 'SAVE_EXISTING_PIPE_LAYERS':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, existingPipeLayers: action.payload} : project
            })
        case 'SAVE_PIPE_NETWORK_EDIT':
            return state.map( project => {
                const updatedPipeState = { ...project };
                action.payload.forEach((data, index) => {
                    const layerName = Object.keys(data);
                    const key = layerName[0];
                    if (updatedPipeState[key]) {
                        updatedPipeState[key] = {
                            ...updatedPipeState[key],
                            materialDetails: data[key]['fields']
                        }
                    }
                })
                return project.projectId === action.projectId ? updatedPipeState : project
            });
        case 'PROCESS_RISER_DATA':
            return state.map( project => {
                return project.projectId === action.projectId ? { ...project, risers: action.payload} : project
            });
        case 'RESET_STATE':
            return state.map( project => {
                return project.projectId === action.projectId ? generateProjectTemplate( action.projectId ) : project
            });
        case 'SET_CALCULATION_RESULTS':
            return state.map( project => {
                return project.projectId === action.projectId ? action.data : project
            });
        default:
            return state;
    }
};
export default guiReducer;