/** Purple Pipe */
export const LICHID_MT_LT = {
  name: "CCR_Liquid_LT_MT",
  label: "Joined Liquid",
  excelName: "CCR_Liquid_LT_MT",
  payloadName: "liquidLine",
  resultName: "GasCoolerLiquidLine"
};

/** Red Pipe */
export const CCR_SUCTION_MT = {
  name: "CCR_Suction_MT",
  label: "Suction MT",
  excelName: "CCR_Suction_MT",
  resultName: "SuctionMT",
  payloadName: "suctionLine",
  flowDirection: 'reverse'
};

/** Blue Pipe */
export const CCR_CONDENSATE_Line = {
  name: "CCR_Condensate_Line",
  label: "Condensate",
  excelName: "CCR_Condensate_Line",
  resultName: "GasCoolerCondensateLine",
  payloadName: "condensateLine",
  flowDirection: 'reverse'
};
/** Orange Pipe */
export const CCR_DISCHARGE_LINE = {
  name: "CCR_Discharge_Line",
  label: "Discharge",
  excelName: "CCR_Discharge_Line",
  resultName: "GasCoolerDischargeLine",
  payloadName: "dischargeLine"
}

/** Blue Pipe */
export const CCR_SUCTION_LT = {
  name: "CCR_Suction_LT",
  excelName: "CCR_Suction_LT",
  label: 'Suction LT',
  payloadName: 'suctionLine',
  resultName: 'BoosterSuctionLT'
}

export const CCR_HOT_GAS = {
  name: "CCR_Hot_gas",
  excelName: "CCR_Hot gas",
  label: 'HotGas Defrost',
  payloadName: 'hotGasLine',
  resultName: 'BoosterDischargeLine'
}

export const ORDEREDLAYERS = [
  CCR_DISCHARGE_LINE,
  CCR_CONDENSATE_Line,
  LICHID_MT_LT,
  CCR_SUCTION_MT,
  CCR_SUCTION_LT,
  CCR_HOT_GAS
]

export const CARRIER_MT = "Carrier_MT"
export const DASHED = "( Dashed )";
export const LT_CABINETS = "CCR_cabinets";
export const MT_GROUPS = "CCR_groups";
export const MT_AIRCOOLER = "CCR_aircooler";
export const MT_TAGS = "CCR_tags";
export const CARRIER_LT = "CARRIER_LT";

export const POINTING_LINES_LAYER="CCR_pointing_lines";

export const CARRIER_SCURGERI = "Carrier_scurgeri";
export const CARRIER_GASCOOLER_MODEL = "Carrier_GasCooler_model";
export const CARRIER_GASCOOLER_NAME = "Carrier_GasCooler_name";
export const CARRIER_RACKS_DESC = "Carrier_Racks_desc";
export const CARRIER_RACKS_NAME = "CCR_racks";

export const CARRIER_ECHIIPAMENTE = "Carrier Echipamente"

export const LAYERS_REQUIRED = [
  {
    name: LICHID_MT_LT.excelName,
    isExist: false
  },
  {
    name: CCR_SUCTION_MT.excelName,
    isExist: false
  },
  {
    name: CCR_CONDENSATE_Line.excelName,
    isExist: false
  },
  {
    name: CCR_DISCHARGE_LINE.excelName,
    isExist: false
  },
  {
    name: CCR_SUCTION_LT.excelName,
    isExist: false
  },
  {
    name: CARRIER_MT,
    isExist: false
  },
  {
    name: LT_CABINETS,
    isExist: false
  },
  {
    name: MT_GROUPS,
    isExist: false
  },
  {
    name: MT_AIRCOOLER,
    isExist: false
  },

  {
    name: MT_TAGS,
    isExist: false
  },
  {
    name: CARRIER_SCURGERI,
    isExist: false
  },
  {
    name: POINTING_LINES_LAYER,
    isExist: false
  },
  {
    name: CARRIER_GASCOOLER_MODEL,
    isExist: false
  },
  {
    name: CARRIER_GASCOOLER_NAME,
    isExist: false
  },
  {
    name: CARRIER_RACKS_NAME,
    isExist: false
  },
  {
    name: CARRIER_RACKS_DESC,
    isExist: false
  },
  {
    name: CARRIER_ECHIIPAMENTE,
    isExist: false
  }
];


export const FLOW_DIRECTION_ICONS =  {
  start: 'start.png',
  stop: 'stop.png',
  tpoint: 't.png',
  down: 'down.png',
  pipeStart: 'pipeStart.png',
  riser: 'riser.png'
}

export const REVERSE_FLOW_DIRECTION_ICONS =  {
  start: 'stop.png',
  stop: 'start.png',
  tpoint: 'reverse-t.png',
  down: 'up.png',
  pipeStart: 'pipeStart.png',
  riser: 'riser.png'
}